import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'
import { CHAIN_ID } from './networks'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 2, 3) should always be at the top of the file.
   */
   {
    pid: 0,
    v1pid: 0,
    lpSymbol: 'HONEY',
    lpAddresses: {
      606626: '0xfdA0F4361258dFD4Cc7f936285874605e46a490D',
      473861: '0x229268FEeaC6C6D620E09F09825a6f3CdA9ec17a',
    },
    token: serializedTokens.syrup,
    quoteToken: serializedTokens.wupro,
  },
  {
    pid: 2,
    // v1pid: 1,
    lpSymbol: 'HONEY-UPRO LP',
    lpAddresses: {
      606626: '0x04a87d67A487C38089736C738aEBc13D6B981c76',
      473861: '0xDA49F6378125d36515e503865F3c260E148aE021',
    },
    token: serializedTokens.honey,
    quoteToken: serializedTokens.wupro,
  },
  {
    pid: 3,
    // v1pid: 2,
    lpSymbol: 'BUSD-UPRO LP',
    lpAddresses: {
      606626: '0xc8EdeC730CC6aBb57c213c840633071164357357',
      473861: '0x397a84e8e1f2b5B009d055a060dE95167fE24208',
    },
    token: serializedTokens.busd,
    quoteToken: serializedTokens.wupro,
  },
  //    * V3 by order of release (some may be out of PID order due to multiplier boost)
  {
    pid: 4,
    // v1pid: 3,
    lpSymbol: 'USDT-UPRO LP',
    lpAddresses: {
      606626: '0x0DC8b02c393B3FD37dA5B3944Ad9d301377C5849',
      473861: '0x8d49d8f6fFc11D6375b3D989b42fAbb469a859FD',
    },
    token: serializedTokens.usdt,
    quoteToken: serializedTokens.wupro,
  },

  
].filter((f) => !!f.lpAddresses[CHAIN_ID])

export default farms
