function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _arrayWithoutHoles(arr) {
    if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}
function _iterableToArray(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _nonIterableSpread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _toConsumableArray(arr) {
    return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}
function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}
import { FacebookIcon, TwitterIcon, TelegramIcon, InstagramIcon, YoutubeIcon, RedditIcon } from "../Svg";
export var footerLinks = [
    {
        label: "About",
        items: [
            {
                label: "Contact",
                href: "https://docs.eenaswap.finance/contact-us"
            },
            {
                label: "Blog",
                href: "https://medium.com/"
            },
            {
                label: "Community",
                href: "https://docs.eenaswap.finance/contact-us/telegram"
            },
            {
                label: "HONEY",
                href: "https://docs.eenaswap.finance/tokenomics/honey"
            },
            {
                label: "\u2014"
            },
            {
                label: "Online Store",
                href: "https://honey.creator-spring.com/",
                isHighlighted: true
            }, 
        ]
    },
    {
        label: "Help",
        items: [
            {
                label: "Customer",
                href: "Support https://docs.eenaswap.finance/contact-us/customer-support"
            },
            {
                label: "Troubleshooting",
                href: "https://docs.eenaswap.finance/help/troubleshooting"
            },
            {
                label: "Guides",
                href: "https://docs.eenaswap.finance/get-started"
            }, 
        ]
    },
    {
        label: "Developers",
        items: [
            {
                label: "Github",
                href: "https://github.com/FuseGoldDevelopers"
            },
            {
                label: "Documentation",
                href: "https://docs.eenaswap.finance"
            },
            {
                label: "Bug Bounty",
                href: "https://app.gitbook.com/@cvs-1/s/cvs/code/bug-bounty"
            },
            {
                label: "Audits",
                href: "https://docs.eenaswap.finance/help/faq#is-cvs-safe-has-cvs-been-audited"
            },
            {
                label: "Careers",
                href: "https://docs.eenaswap.finance/hiring/become-a-chef"
            }, 
        ]
    }, 
];
export var socials = [
    {
        label: "Facebook",
        icon: FacebookIcon,
        href: "https://www.facebook.com/UltraProchain"
    },
    {
        label: "Twitter",
        icon: TwitterIcon,
        href: "https://twitter.com/UltraProchain"
    },
    {
        label: "Telegram",
        icon: TelegramIcon,
        href: "https://t.me/ultraproscan"
    },
    // {
    //   label: "Medium",
    //   icon: MediumIcon,
    //   href: "https://theupronetwork.medium.com",
    // },
    {
        label: "Instagram",
        icon: InstagramIcon,
        href: "https://www.instagram.com/ultraprochain/"
    },
    {
        label: "Youtube",
        icon: YoutubeIcon,
        href: " https://www.youtube.com/channel/UCxNfnJGtUDOV8ClRBshs04w"
    },
    // {
    //   label: "Github",
    //   icon: GithubIcon,
    //   href: "https://github.com/upronetwork",
    // },
    {
        label: "Reddit",
        icon: RedditIcon,
        href: "https://www.reddit.com/user/ultraproscan"
    }, 
];
export var langs = _toConsumableArray(Array(20)).map(function(_, i) {
    return {
        code: "en".concat(i),
        language: "English".concat(i),
        locale: "Locale".concat(i)
    };
});
